<template>
  <div class="box">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            @click="dialogVisible = true"
            class="header_imgs"
            src="../../assets/learning_banner.png"
            alt=""
          />
        </div>
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="module">
      <div class="w">
        <div class="title">E-Learning系统</div>
        <div class="tablist ssss">
          <div
            @click="yd('top1')"
            :class="active == 1 ? 'item avtive' : 'item'"
          >
            云课堂
          </div>
          <div
            @click="yd('top2')"
            :class="active == 2 ? 'item avtive' : 'item'"
          >
            多元化学习功能
          </div>
          <div
            @click="yd('top3')"
            :class="active == 3 ? 'item avtive' : 'item'"
          >
            学习管理
          </div>
          <div
            @click="yd('top4')"
            :class="active == 4 ? 'item avtive' : 'item'"
          >
            智能管班
          </div>
          <div
            @click="yd('top5')"
            :class="active == 5 ? 'item avtive' : 'item'"
          >
            直播课堂
          </div>
        </div>
        <div id="top1" class="items" style="margin-top: 100px">
          <img src="../../assets/learning_tu1_imgs.png" alt="" />
          <div class="itemsrigth">
            <div class="titles">云课堂</div>
            <div class="introduce">一键构建自主云课堂</div>
            <div class="brief">
              可一键搭建企业专属在线培训云课堂，员工可通过企业自己的云课堂入口登录学习。
            </div>
            <div class="btn" @click="dialogVisible = true">立即咨询</div>
          </div>
        </div>
        <div id="top2" class="itemss">
          <div class="itemsleft">
            <div class="titles">多元化学习功能</div>
            <div class="introduce">听学看练考五大模块</div>
            <div class="brief">
              学员可以通过听音频、学H5、看视频、练题目、考试卷等功能学习掌握理论知识
            </div>
            <div class="btn" @click="dialogVisible = true">立即咨询</div>
          </div>
          <img src="../../assets/learning_tu2_imgs.png" alt="" />
        </div>
        <div id="top3" class="items">
          <img src="../../assets/learning_tu3_imgs.png" alt="" />

          <div class="itemsrigth">
            <div class="titles">学习管理</div>
            <div class="introduce">强大的学习管理功能</div>
            <div class="brief">
              可以在线实时监管所有学员的在线学习情况，包括学习时长、视频、做题、考试、签到等数据。
            </div>
            <div class="btn" @click="dialogVisible = true">立即咨询</div>
          </div>
        </div>
        <div id="top4" class="itemss">
          <div class="itemsleft">
            <div class="titles">智能管班</div>
            <div class="introduce">方便快捷的班级管理</div>
            <div class="brief">
              支持将整班级学习数据导入成表，方便管理者筛选管理，并可以通过管理平台了解整班的总体学习进度。
            </div>
            <div class="btn" @click="dialogVisible = true">立即咨询</div>
          </div>
          <img src="../../assets/learning_tu4_imgs.png" alt="" />
        </div>
        <div id="top5" class="items">
          <img src="../../assets/learning_tu5_imgs.png" alt="" />
          <div class="itemsrigth">
            <div class="titles">直播课堂</div>
            <div class="introduce">实现自主直播课堂</div>
            <div class="brief">
              企业可以创建在线直播课程，支持在线直播和回放功能，提升企业实时培训体验。
            </div>
            <div class="btn" @click="dialogVisible = true">立即咨询</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      active: 1,
      top1: null,
      top2: null,
      top3: null,
      top4: null,
      top5: null,
    };
  },
  methods: {
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
    yd(val) {
      window.scrollTo(0, this[val]);
    },
    zx() {
      this.$router.push("/contact");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.top1 = document.getElementById("top1").offsetTop;
    this.top2 = document.getElementById("top2").offsetTop;
    this.top3 = document.getElementById("top3").offsetTop;
    this.top4 = document.getElementById("top4").offsetTop;
    this.top5 = document.getElementById("top5").offsetTop;
    let that = this;
    window.onscroll = function () {
      var t = document.documentElement.scrollTop || document.body.scrollTop; //变量t就是滚动条滚动时，到顶部的距离
      if (t > 800 && t < 1200) {
        that.active = 1;
      } else if (t > 1200 && t < 2000) {
        that.active = 2;
      } else if (t > 2000 && t < 2600) {
        that.active = 3;
      } else if (t > 2600 && t < 3200) {
        that.active = 4;
      } else if (t > 3200) {
        that.active = 5;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.ssss {
  position: sticky;
  top: 73px;
}
.box {
  position: relative;
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 74px;
}
.module {
  border-top: 1px solid transparent;
  background: url("../../assets/beijingtu.png");
  .title {
    text-align: center;
    margin-top: 84px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .tablist {
    margin-top: 30px;
    margin-bottom: 96px;
    display: flex;
    justify-content: space-around;
    line-height: 80px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(179, 204, 255, 0.5);
    border-radius: 10px;
    height: 80px;
    width: 1200px;
    .item {
      cursor: pointer;
      position: relative;
    }
    .avtive {
      &::before {
        position: absolute;
        left: 50%;
        bottom: 0;
        content: "";
        transform: translateX(-50%);
        width: 96px;
        height: 5px;
        background: #0e89ff;
      }
    }
  }
  .items {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;
    .itemsrigth {
      width: 360px;
      overflow: hidden;
      margin-top: 80px;
      // margin-left: 140px;
      .titles {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1d1d1e;
      }
      .introduce {
        margin-top: 10px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1d1d1e;
      }
      .brief {
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8d8d8d;
      }
      .btn {
        cursor: pointer;
        margin-top: 83px;
        text-align: center;
        line-height: 67px;
        width: 307px;
        height: 67px;
        background: #0e89ff;
        border-radius: 34px;
        font-size: 34px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .itemss {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;

    .itemsleft {
      width: 360px;
      overflow: hidden;
      // margin-right: 130px;
      margin-top: 80px;

      .titles {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1d1d1e;
      }
      .introduce {
        margin-top: 10px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1d1d1e;
      }
      .brief {
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8d8d8d;
      }
      .btn {
        margin-top: 83px;
        text-align: center;
        line-height: 67px;
        width: 307px;
        height: 67px;
        background: #0e89ff;
        border-radius: 34px;
        font-size: 34px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
